import { gql } from '@apollo/client';
import { find, get } from 'lodash';
import React from 'react';

import { evictCachedAllowanceData } from '@Hooks/use-dashboard-allowance';
import { trackEvent } from '@Tracking';
import { permissionsChanged } from '@Tracking/events';

import { getUserDisplayName } from '../../admin/helpers';
import { userById } from '../../admin/reducer';
import { formatMessage } from '../../lib/i18n';
import { auth as reconnectOAuth } from '../../lib/oauth';
import { open as openPopup } from '../../lib/popup-window';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { userPermissionConstants } from '../../lib/tracker';
import { validateEmailAddresses } from '../../lib/user';
import { router } from '../../router';
import apolloClient from '../../services/concierge-service/apollo-client';
import {
  deleteServiceAccount as deleteDataSourceService,
  getAllServiceAccounts,
  getPeople as getPeopleService,
} from '../../services/management-service';
import * as permissionService from '../../services/permission-service';
import {
  actions as toastActions,
  ToastTypes,
} from '../../toast/reducer/toast-slice';

const UserAllowancesQuery = gql(`
  query UserAllowances {
    currentUser {
      id
      organization {
        id
        allowances {
          editors {
            usage
            hardLimit
          }
          viewers {
            usage
            hardLimit
          }
        }
      }
    }
  }
`);

export const getPeopleStart = createAction('Groups:GET_PEOPLE_START');
export const getPeopleSuccess = createAction('Groups:GET_PEOPLE_SUCCESS');
export const getPeopleFailed = createErrorAction('Groups:GET_PEOPLE_FAILED');

export const getDataSourcesStart = createAction(
  'Groups:GET_DATA_SOURCES_START',
);
export const getDataSourcesSuccess = createAction(
  'Groups:GET_DATA_SOURCES_SUCCESS',
);
export const getDataSourcesFailed = createErrorAction(
  'Groups:GET_DATA_SOURCES_FAILED',
);

export const setPermissionsStart = createAction('Groups:SET_PERMISSIONS_START');
export const setPermissionsSuccess = createAction(
  'Groups:SET_PERMISSIONS_SUCCESS',
);
export const setPermissionsFailed = createErrorAction(
  'Groups:SET_PERMISSIONS_FAILED',
);

export const revokeAccountOwnerStart = createAction(
  'Groups:REVOKE_ACCOUNT_OWNER_START',
);
export const revokeAccountOwnerSuccess = createAction(
  'Groups:REVOKE_ACCOUNT_OWNER_SUCCESS',
);
export const revokeAccountOwnerFailed = createErrorAction(
  'Groups:REVOKE_ACCOUNT_OWNER_FAILED',
);

export const makeAccountOwnerStart = createAction(
  'Groups:MAKE_ACCOUNT_OWNER_START',
);
export const makeAccountOwnerSuccess = createAction(
  'Groups:MAKE_ACCOUNT_OWNER_SUCCESS',
);
export const makeAccountOwnerFailed = createErrorAction(
  'Groups:MAKE_ACCOUNT_OWNER_FAILED',
);

export const demoteToDashboardViewOnlyStart = createAction(
  'Groups:DEMOTE_TO_DASHBOARD_VIEW_ONLY_START',
);
export const demoteToDashboardViewOnlySuccessful = createAction(
  'Groups:DEMOTE_TO_DASHBOARD_VIEW_ONLY_SUCCESSFUL',
);
export const demoteToDashboardViewOnlyFailed = createAction(
  'Groups:DEMOTE_TO_DASHBOARD_VIEW_ONLY_FAILED',
);

export const promoteToMemberStart = createAction(
  'Groups:PROMOTE_TO_MEMBER_START',
);
export const promoteToMemberSuccess = createAction(
  'Groups:PROMOTE_TO_MEMBER_SUCCESS',
);
export const promoteToMemberFailed = createErrorAction(
  'Groups:PROMOTE_TO_MEMBER_FAILED',
);

export const inviteMembersStart = createAction('Groups:INVITE_MEMBERS_START');
export const inviteMembersSuccess = createAction(
  'Groups:INVITE_MEMBERS_SUCCESS',
);
export const inviteMembersFailed = createErrorAction(
  'Groups:INVITE_MEMBERS_FAILED',
);
export const inviteMembersEmailInvalid = createErrorAction(
  'Groups:INVITE_MEMBERS_EMAIL_INVALID',
);
export const inviteMembersClearError = createErrorAction(
  'Groups:INVITE_MEMBERS_CLEAR_ERROR',
);

export const reconnectDataSourceStart = createAction(
  'Groups:RECONNECT_DATA_SOURCE_START',
);
export const reconnectDataSourceSuccess = createAction(
  'Groups:RECONNECT_DATA_SOURCE_SUCCESS',
);
export const reconnectDataSourceCancelled = createAction(
  'Groups:RECONNECT_DATA_SOURCE_CANCELLED',
);
export const reconnectDataSourceFailed = createErrorAction(
  'Groups:RECONNECT_DATA_SOURCE_FAILED',
);

export const deleteDataSourceStart = createAction(
  'Groups:DELETE_DATA_SOURCE_START',
);
export const deleteDataSourceSuccess = createAction(
  'Groups:DELETE_DATA_SOURCE_SUCCESS',
);
export const deleteDataSourceFailed = createErrorAction(
  'Groups:DELETE_DATA_SOURCE_FAILED',
);

const trackPermissionsChange = (prevRole, nextRole, source) => {
  const properties = {
    target_user_previous_permission: prevRole,
    target_user_new_permission: nextRole,
  };

  if (source) properties.source = source;

  trackEvent(
    permissionsChanged({
      'From permission': prevRole,
      'To permission': nextRole,
    }),
  );
};

export const getPeople = () => async (dispatch) => {
  dispatch(getPeopleStart());

  try {
    const people = await getPeopleService();

    dispatch(getPeopleSuccess({ people }));
  } catch (error) {
    dispatch(getPeopleFailed(error));

    // Re-throw error as this action is used in
    // `inviteMembers` and `openUserPermissionsModal`
    throw error;
  }
};

export const getDataSources = () => async (dispatch) => {
  dispatch(getDataSourcesStart());

  try {
    const dataSources = await getAllServiceAccounts();

    dispatch(getDataSourcesSuccess({ dataSources }));
  } catch (error) {
    dispatch(getDataSourcesFailed(error));
  }
};

const getNameForUserWithId = (getState, userId) => {
  const { allUsers } = getState();

  const user = userById(allUsers, userId);

  return getUserDisplayName(user);
};

const getEmailForUserWithId = (getState, userId) => {
  const { allUsers } = getState();

  const { email } = userById(allUsers, userId) || {};

  return email;
};

const getRoleForUser = (getState, userId) => {
  const { allUsers } = getState();
  const { permission } = find(allUsers.members, { id: userId });
  return permission;
};

const getTrackingStringFromRole = (role) => {
  return role === 'admin'
    ? userPermissionConstants.GROUP_ADMIN
    : userPermissionConstants.GROUP_VIEWER;
};

export const setPermissions =
  (groupId, userId, role) => async (dispatch, getState) => {
    const previousRole = getRoleForUser(getState, userId);
    dispatch(setPermissionsStart({ userId, role }));

    try {
      await permissionService.setRole(groupId, userId, role);

      evictCachedAllowanceData(apolloClient.create().cache);

      trackPermissionsChange(
        getTrackingStringFromRole(previousRole),
        getTrackingStringFromRole(role),
      );

      dispatch(setPermissionsSuccess());
    } catch (error) {
      dispatch(setPermissionsFailed(error, { userId, role: previousRole }));
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const revokeAccountOwner =
  (userId, groupId) => async (dispatch, getState) => {
    dispatch(revokeAccountOwnerStart(userId));

    try {
      const revokeeName = getNameForUserWithId(getState, userId);

      await permissionService.revokeAccountOwner(userId, groupId);

      trackPermissionsChange(
        userPermissionConstants.OWNER,
        userPermissionConstants.GROUP_ADMIN,
      );

      dispatch(revokeAccountOwnerSuccess({ userId }));
      dispatch(
        toastActions.showSuccessToast(
          formatMessage('groups.people.revokeOwnerSuccessful', { revokeeName }),
        ),
      );
    } catch (error) {
      dispatch(revokeAccountOwnerFailed(error, userId));
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const makeAccountOwner = (userId) => async (dispatch, getState) => {
  dispatch(makeAccountOwnerStart(userId));

  try {
    await permissionService.makeAccountOwner(userId);

    const previousRole = getRoleForUser(getState, userId);

    evictCachedAllowanceData(apolloClient.create().cache);

    trackPermissionsChange(
      getTrackingStringFromRole(previousRole),
      userPermissionConstants.OWNER,
    );

    dispatch(makeAccountOwnerSuccess(userId));
    dispatch(
      toastActions.showSuccessToast(
        formatMessage('groups.people.makeOwnerSuccessful'),
      ),
    );
  } catch (error) {
    dispatch(makeAccountOwnerFailed(error, userId));
    dispatch(toastActions.showGenericErrorToast());
  }
};

export const demoteToDashboardViewOnly =
  (groupId, userId, source = 'Group members page') =>
  async (dispatch, getState) => {
    try {
      dispatch(demoteToDashboardViewOnlyStart({ userId }));
      await permissionService.removeUserFromGroup(groupId, userId);

      evictCachedAllowanceData(apolloClient.create().cache);

      const previousRole = getRoleForUser(getState, userId);

      trackPermissionsChange(
        getTrackingStringFromRole(previousRole),
        userPermissionConstants.DASHBOARD_VIEWER,
        source,
      );

      dispatch(demoteToDashboardViewOnlySuccessful({ userId }));
      dispatch(
        toastActions.showToast({
          type: ToastTypes.DEMOTED_TO_DASHBOARD_VIEW_ONLY,
          userId,
        }),
      );
    } catch (e) {
      dispatch(demoteToDashboardViewOnlyFailed({ userId }));
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const promoteToMember =
  (userId, groupId) => async (dispatch, getState) => {
    dispatch(promoteToMemberStart({ userId }));

    try {
      const userName = getNameForUserWithId(getState, userId);
      const email = getEmailForUserWithId(getState, userId);

      await permissionService.promoteToMember(email, groupId);

      trackPermissionsChange(
        userPermissionConstants.DASHBOARD_VIEWER,
        userPermissionConstants.GROUP_VIEWER,
      );

      dispatch(promoteToMemberSuccess({ userId }));
      dispatch(
        toastActions.showSuccessToast(
          formatMessage('groups.people.promoteToMemberSuccessful', {
            userName,
          }),
        ),
      );
    } catch (error) {
      dispatch(promoteToMemberFailed(error, { userId }));
      dispatch(toastActions.showGenericErrorToast());
    }
  };

const getInviteErrorPayload = (error) => {
  if (!error.errors) {
    return { id: 'people.errorMessage' };
  }

  const [firstError] = error.errors;

  const getEmailAddressValues = () => {
    const { addresses: faultyAddresses = [] } = firstError;
    const count = faultyAddresses.length;
    const addressesStr = faultyAddresses.join(', ');
    return { addressesStr, count };
  };

  switch (firstError.type) {
    case 'ErrInviteLimitExceeded':
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { count: remaining = 0 } = firstError;
      if (remaining === 0) {
        return {
          id: 'people.errorMessage.errInviteLimitExceededZero',
          values: {
            a: (chunk) => (
              <a href="/billing/plans" className="linkable">
                {chunk}
              </a>
            ),
          },
        };
      }

      return {
        id: 'people.errorMessage.errInviteLimitExceeded',
        values: { remaining },
      };
    case 'ErrInAnotherOrganization':
      return {
        id: 'people.errorMessage.errInAnotherOrganization',
        values: getEmailAddressValues(),
      };
    case 'ErrAlreadyHasAccess':
      return {
        id: 'people.errorMessage.errAlreadyHasAccess',
        values: getEmailAddressValues(),
      };
    default:
      return { id: 'people.errorMessage' };
  }
};

export const inviteMembers =
  (addresses, role) => async (dispatch, getState) => {
    const { user: { user: { default_group_id: groupId } = {} } = {} } =
      getState();
    const emails = addresses.split(',').map((s) => s.trim());

    if (!validateEmailAddresses(emails)) {
      dispatch(inviteMembersEmailInvalid());
      return;
    }

    dispatch(inviteMembersStart());

    const newUsers = emails.map((email) => ({ type: 'user', role, email }));

    try {
      const { data } = await apolloClient.create().query({
        query: UserAllowancesQuery,
      });

      const {
        currentUser: {
          organization: {
            allowances: { editors, viewers },
          },
        },
      } = data;

      const isEditor = role === 'admin';
      const allowance = isEditor ? editors : viewers;
      const newUsage = allowance.usage + emails.length;

      await permissionService.inviteUsersToGroup(groupId, newUsers);

      evictCachedAllowanceData(apolloClient.create().cache);

      dispatch(inviteMembersSuccess());
      dispatch(getPeople());
      dispatch(
        toastActions.showSuccessToast(formatMessage('people.inviteSent')),
      );

      if (newUsage > allowance.hardLimit) {
        router.setQueryParam('feature', isEditor ? 'editors' : 'viewers');
        router.setQueryParam('modal', 'overQuota');
      } else {
        router.removeQueryParam('modal');
      }
    } catch (error) {
      const payload = getInviteErrorPayload(error);

      dispatch(inviteMembersFailed(error, payload));
    }
  };

export const deleteDataSource =
  (dataSourceId, deleteAssociatedWidgets) => async (dispatch, getState) => {
    dispatch(deleteDataSourceStart());
    const groupId = get(getState(), 'user.user.default_group_id');

    try {
      await deleteDataSourceService(
        groupId,
        dataSourceId,
        deleteAssociatedWidgets,
      );
      dispatch(deleteDataSourceSuccess({ dataSourceId }));
      dispatch(
        toastActions.showSuccessToast(
          formatMessage('groups.deleteDataSourceSuccessMessage'),
        ),
      );
    } catch (error) {
      dispatch(deleteDataSourceFailed());
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const reconnectDataSource =
  (dataSource, onSuccess) => async (dispatch) => {
    const { service, id: dataSourceId } = dataSource;

    dispatch(reconnectDataSourceStart());

    try {
      const popup = openPopup('geckoOAuth');
      await reconnectOAuth(popup, service, {}, dataSourceId);

      onSuccess();
      dispatch(toastActions.showSuccessToast('Connection updated'));
      dispatch(reconnectDataSourceSuccess({ dataSourceId }));
    } catch (error) {
      if ('Window closed' === error.message) {
        dispatch(reconnectDataSourceCancelled({ dataSourceId }));
      } else {
        dispatch(reconnectDataSourceFailed(error, { dataSourceId }));
        dispatch(
          toastActions.showErrorToast(
            formatMessage('datasources.reconnectionFailed'),
          ),
        );
      }
    }
  };

export const resendInvite = (groupId, userId) => async (dispatch, getState) => {
  try {
    await permissionService.resendInvite(groupId, userId);

    const { owners, members, viewers } = getState().allUsers;
    const { email } = [...owners, ...members, ...viewers].find(
      (user) => user.id === userId,
    );

    dispatch(
      toastActions.showSuccessToast(
        formatMessage('people.resendInviteSuccess', { email }),
      ),
    );
  } catch (err) {
    if (err.type === 'ErrResendInviteRateLimitExceeded') {
      dispatch(
        toastActions.showErrorToast(
          formatMessage('people.errorMessage.errResendInviteRateLimitExceeded'),
        ),
      );
    } else {
      dispatch(toastActions.showGenericErrorToast());
    }
  }
};
